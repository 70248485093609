import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";

export default {
    'get-products': {
        onLoad: true,
        action: (lang, match, query, data) => {
            return fetch(env.API_URL + '/data/orders/stock', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField ? query.sortField == 'out' ? 'quantityOut' : query.sortField : null,
                    selectedYear: localStorage && localStorage.year ? localStorage.year : null,
                    activeOrder: localStorage && localStorage.activeOrder ? localStorage.activeOrder : null,
                    additionalFilters: {
                        article: query && query.article,
                        manufacturer: query && query.manufacturer,

                    }

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'save-order': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/save/order', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },

    'get-manufacturers': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/manufacturer', {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return [
                        ...result,
                    ]
            })
        }
    },
    'get-order': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/get/order', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return [
                        ...result,
                    ]
            })
        }
    },
    'check-quantity': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/stock/check-quantity/' + data.id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },
    'check-series': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/stock/check-series', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        ...result
                    }
            })
        }
    },

    





}