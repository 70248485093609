import OrderPage from "./order";
import LoginPage from './login';
import HomePage from './home';
import OrdersInProgress from "./ordersInProgress";



export const routes = [
    HomePage,
    OrderPage,
    LoginPage,
    OrdersInProgress
]

