import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import Isvg from 'react-inlinesvg';
import arrow from '../../assets/arrow-down.svg';

class DropdownSelect extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            openDropdown: false
        }
    }
    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ openDropdown: false })
        }
    }

    render() {
        return (
            <FormGroup className={`position-relative ${this.props.className ? this.props.className : ''}`} style={{ marginBottom: 0 }}>
                <div className="dropdown-select-field" ref={this.wrapperRef}>
                    <div className={`dropdown-select`} onClick={() => this.setState({ openDropdown: !this.state.openDropdown })}>
                        {this.props.value && this.props.values && this.props.values.filter(item => item.value == this.props.value)[0] ? this.props.values.filter(item => item.value == this.props.value)[0].name : this.props.label}
                    </div>
                    {
                        this.state.openDropdown ?
                            <ul className="dropdown-items-wrap">
                                {
                                    this.props.values.map((item, idx) => {
                                        return (
                                            <li className={this.props.value == item.value ? 'active-item' : ''} onClick={() => {
                                                this.props.onChange(item.value)
                                                this.setState({ openDropdown: false})
                                            }}>{item.name}</li>
                                        )
                                    })
                                }
                            </ul>
                            :
                            null
                    }
                   

                </div>
     

            </FormGroup>
        )
    }
}

export default DropdownSelect;