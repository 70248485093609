import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link, Redirect } from 'react-router-dom';

import logo from '../assets/logo.svg';
import menuIcon from '../assets/hamburger.svg';
import micIcon from '../assets/mic.svg';
import testIcon from '../assets/sidebarTestIcon.svg';
import orderIcon from '../assets/orderIcon.svg';
import dollarIcon from '../assets/dollar.svg';

import banerSideber from '../assets/baner2.png';


import env from "react-dotenv";


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownMenu: false,
            subDropdownMenu: false,
            subSubDropdownMenu: false,
            rotateSubArrow: false
        }
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {

    }


    render() {
        let pathname = this.props[0].location.pathname;
        return (
            <div className={this.props.sidebarShort ? 'sidebar sidebar-short' : 'sidebar'}>
                <div className="sidebar-scroll-area">
                    <div className="sidebar-header">
                        <Isvg src={logo} className="logo" />
                        {/* <div className="menu-icon-box">
                            <Isvg src={menuIcon} className="menu-icon" onClick={() => this.props.sidebarChange()} />
                        </div> */}
                    </div>
                    <div className="sidebar-body">
                        <ul className="main-menu">

                            <li>
                                <div className={pathname == '/' ? "main-menu-block-active" : "main-menu-block"}>
                                    <Link to="/">
                                        <div className={pathname == '/' ? 'sidebar-icon-box-active' : 'sidebar-icon-box'}>
                                            <Isvg src={orderIcon} />
                                        </div>
                                        <span className="link-text">KREIRAJ NARUDŽBU</span>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={pathname == '/narudzbe-u-pripremi' ? "main-menu-block-active" : "main-menu-block"}>
                                    <Link to="/narudzbe-u-pripremi">
                                        <div className={pathname == '/narudzbe-u-pripremi' ? 'sidebar-icon-box-active' : 'sidebar-icon-box'}>
                                            <Isvg src={dollarIcon} />
                                        </div>
                                        <span className="link-text">NARUDŽBE U PRIPREMI</span>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={pathname == '/prethodne-narudzbe' ? "main-menu-block-active" : "main-menu-block"}>
                                    <Link to="/prethodne-narudzbe">
                                        <div className={pathname == '/prethodne-narudzbe' ? 'sidebar-icon-box-active' : 'sidebar-icon-box'}>
                                            <Isvg src={testIcon} />
                                        </div>
                                        <span className="link-text">PRETHODNE NARUDŽBE</span>
                                    </Link>
                                </div>
                            </li>





                        </ul>
                        {
                            this.props.banners && this.props.banners.bannerSidebar1 && this.props.banners.bannerSidebar1.image ?
                                <div className='banner-sidebar'
                                    style={this.props.banners.bannerSidebar1.link ? { cursor: 'pointer' } : []}
                                    onClick={() => {
                                        if (this.props.banners.bannerSidebar1.link && typeof window != 'undefined') {
                                            window.open(this.props.banners.bannerSidebar1.link, '_blank');
                                        }
                                    }}
                                >
                                    {/* <img src={this.props.banners.bannerSidebar1.image} /> */}
                                    <img src={banerSideber} />
                                </div>
                                :
                                null
                        }
                        {
                            this.props.banners && this.props.banners.bannerSidebar2 && this.props.banners.bannerSidebar2.image ?
                                <div className='banner-sidebar'
                                    style={this.props.banners.bannerSidebar2.link ? { cursor: 'pointer' } : []}
                                    onClick={() => {
                                        if (this.props.banners.bannerSidebar2.link && typeof window != 'undefined') {
                                            window.open(this.props.banners.bannerSidebar2.link, '_blank');
                                        }
                                    }}
                                >
                                    {/* <img src={this.props.banners.bannerSidebar2.image} /> */}
                                    <img src={banerSideber} />
                                </div>
                                :
                                null
                        }

                    </div>
                </div>
                {/* <div className="sidebar-footer">
                    <div className="fast-help">
                        <Isvg src={micIcon} />
                        <h6>Potrebna Vam je pomoć?</h6>
                        <p>Slobodno nas kontaktirate</p>
                        <button>POKRENI POZIV</button>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Sidebar;
