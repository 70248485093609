import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import printJS from 'print-js'


export default {
    'get-finished-orders': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(env.API_URL + '/data/orders/finished/get', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType,
                    sortField: query.sortField,

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'print-export-order': {
        onLoad: false,
        action: (data) => {
            return fetch(env.API_URL + '/data/orders/print', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                responseType: 'blob',
                body: JSON.stringify(data)
            }).then((response) => {
                if (response.ok) { // checks if the response is with status 200 (successful)
                    return response.blob().then(blob => {
                        let pdfFile = new Blob([blob], {
                            type: "application/pdf"
                        });
                        let pdfUrl = URL.createObjectURL(pdfFile);
                        // window.open(pdfUrl);
                        printJS(pdfUrl);
                    });
                }
            })
            // .then(parseJSON).then(({ result, status }) => {
            //     if (status >= 200 && status < 300)
            //         return {
            //             ...result
            //         }
            // })
        }
    },






}