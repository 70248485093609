

import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import infoIcon from '../assets/infoIcon.svg';

import Isvg from 'react-inlinesvg';


class InfoModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>

                <ModalBody className="delete-modal">
                    <div className='info-icon-wrap'>
                        <Isvg src={infoIcon} />
                    </div>
                    <div className="message" style={{ fontSize: 18 }}>
                        {
                            this.props.info
                        }
                    </div>
                </ModalBody>
                <ModalFooter className="info-modal-footer">
                    {
                        this.props.buttons && this.props.buttons.map((item, idx) => {
                            return (
                                <Button className={item.className ? item.className : 'info-modal-button'} color={item.color ? item.color : 'primary'} onClick={item.onClick}>{item.text}</Button>
                            )
                        })
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default InfoModal;