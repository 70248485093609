import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { uuid } from 'uuidv4'

class Text extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen: false,
            valueText: null,
            inputID: uuid(),
            selectedValue: null
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.value != prevProps.value && !this.props.value) {
            this.setState({ isOpen: false, valueText: '' })
        }
        if (prevProps.value != this.props.value && this.props.value) {
            let value = this.props.value;
            let values = this.props.values;
            if (values && values.length == 0) {
                this.setState({ valueText: null })
            } else if (values && ((values.filter(item => item.value == value).length && values.filter(item => item.value == value)[0].name && this.state.valueText != values.filter(item => item.value == value)[0].name) || (values.filter(item => item.value == Number(value)).length && values.filter(item => item.value == Number(value))[0].name && this.state.valueText != values.filter(item => item.value == Number(value))[0].name))) {
                this.setState({ valueText: values.filter(item => item.value == value)[0].name })
            } else {
                this.setState({ valueText: this.props.value })
            }
        } else if (this.state.valueText) {
            this.setState({ valueText: null })
        }
        if (prevState.selectedValue != this.state.selectedValue && this.state.selectedValue) {
            if (this.props.values && this.props.values.length && this.refDropdown) {
                let prevIndex;
                if (prevState.selectedValue)
                    prevIndex = this.props.values.findIndex(el => el.value == prevState.selectedValue)

                let index = this.props.values.findIndex(el => el.value == this.state.selectedValue)
                let elementId = `select-item-${index}`
                let refInfo = this.refDropdown.getBoundingClientRect();
                let elementInfo = document.getElementById(elementId).getBoundingClientRect()
                let minPosition = 0;
                let maxPosition = 0;
                minPosition = refInfo.top;
                maxPosition = refInfo.top + refInfo.height - 24;
                let elementPosition = 0;
                elementPosition = elementInfo.top;
                let maxElements = (maxPosition - minPosition) / 24 - 1;

                if (prevIndex && prevIndex < index) {
                    if (maxElements < index) {
                        this.refDropdown.scrollTop = 24 * (index - maxElements);
                    }
                } else if (prevIndex && prevIndex > index) {
                    if (minPosition > elementPosition)
                        this.refDropdown.scrollTop -= 24;
                }
                //  else if (prevIndex == (this.props.values.length - 1) && index == 0) {
                //     this.refDropdown.scrollTop = 0;
                // } else if (index == (this.props.values.length - 1) && prevIndex == 0) {
                //     this.refDropdown.scrollTop = 24 * (index - maxElements);;
                // } 


            }
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName}`} style={this.props.style ? this.props.style : {}} >
                <div ref={this.wrapperRef}>
                    <Label>{this.props.label}</Label>

                    <input autoComplete="off" id={this.state.inputID} className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={this.props.textType ? this.props.textType : "text"}
                        ref={(node) => {
                            if (this.props.setFieldRef) {
                                this.props.setFieldRef(node)
                            }
                        }}
                        onKeyUp={(e) => {
                            if (e.key == this.props.finishKey) {
                                this.setState({ isOpen: false })
                                if (this.state.selectedValue) {
                                    this.props.onChange(this.state.selectedValue)
                                }
                                if (this.props.focusNext)
                                    this.props.focusNext();
                            }
                            if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {

                                let value;
                                if (this.props.values && this.props.values.length) {
                                    if (this.state.selectedValue) {
                                        let index = this.props.values.findIndex(el => el.value == this.state.selectedValue)
                                        if (e.key == 'ArrowUp') {
                                            if (index > 0) {
                                                value = this.props.values[index - 1].value
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                            // else {
                                            //     value = this.props.values[this.props.values.length - 1].value
                                            // }
                                        } else if (e.key == 'ArrowDown') {
                                            if (index < this.props.values.length - 1) {
                                                value = this.props.values[index + 1].value
                                            } else {
                                                value = this.state.selectedValue;
                                            }
                                            // else {
                                            //     value = this.props.values[0].value
                                            // }
                                        }
                                    } else {
                                        value = this.props.values[0].value;
                                    }

                                }
                                this.setState({
                                    selectedValue: value
                                })
                            } else {
                                if (this.state.selectedValue) {
                                    this.setState({ selectedValue: null })
                                }
                            }
                        }}
                        onChange={(e) => {
                            // if(!this.props.disabled){
                            this.props.onChange(e.target.value)
                            this.setState({ isOpen: true })
                            // }
                        }}
                        // onBlur={() => this.setState({ isOpen: false })}
                        onFocus={() => this.setState({ isOpen: true })}
                        disabled={this.props.readOnly}
                        // value={this.props.value ? this.props.value : ''}
                        value={this.props.showName ? this.state.valueText : this.props.value ? this.props.value : ''}
                        //value={!this.props.readOnly ? this.props.value ? this.props.value : '' : ''}
                        placeholder={this.props.placeholder}
                    ></input>
                    {
                        this.props.values && this.props.values.length && this.state.isOpen ?
                            <div className="select-with-search-list-wrap" ref={node => this.refDropdown = node}>
                                {
                                    this.props.values.map((item, idx) => {
                                        return (
                                            <div
                                                className={
                                                    "select-with-search-list-item " +
                                                    (item.disabled ? "select-with-search-list-item-disabled" : "")
                                                    +
                                                    (item.value == this.state.selectedValue && this.state.selectedValue ? 'selected-item' : '')
                                                }
                                                onClick={() => {
                                                    if (item.disabled) {
                                                        return;
                                                    }
                                                    this.props.onChange(item.value)
                                                    this.setState({ isOpen: false }, () => {
                                                        document.getElementById(this.state.inputID).focus();
                                                        this.setState({ isOpen: false })
                                                    })
                                                }}
                                                id={`select-item-${idx}`}
                                            >
                                                {item.name}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :
                            null
                    }
                    {this.props.error && this.props.touched ?
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                        :

                        null
                    }

                </div>

            </FormGroup>
        )
    }
}

export default Text;
