// import { Component } from "react";

// import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';
// import Isvg from 'react-inlinesvg';
// import arrow from '../../assets/arrow-down.svg';

// class Select extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {}
//     }

//     render() {
//         return (
//             <FormGroup className="position-relative">
//                 <Label>{this.props.label}</Label>
//                 <div className="select-field">
//                     <select
//                         className={`form-control`}
//                         // className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`}
//                         disabled={this.props.disableValue}
//                         ref={(node) => this.props.setFieldRef(node)}
//                         onChange={(e) => this.props.onChange(e.target.value)}
//                         value={this.props.value ? this.props.value : ''}
//                         placeholder={this.props.placeholder}

//                         onKeyUp={(e) => {
//                             if (e.key == this.props.finishKey) {
//                                 this.props.focusNext();
//                             }
//                         }}

//                     >
//                         {
//                             this.props.values.map((item, idx) => {
//                                 return (
//                                     <option value={item.value}>{item.name}</option>
//                                 )
//                             })
//                         }
//                     </select>
//                     <Isvg src={arrow} />
//                 </div>
//                 {this.props.error && this.props.touched ?
//                     <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
//                     :

//                     null
//                 }

//             </FormGroup>
//         )
//     }
// }

// export default Select;


import React, { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

import { uuid } from 'uuidv4'

class Text extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen: false,
            valueText: null,
            inputID: uuid()
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // if (this.props.value != prevProps.value && !this.props.value) {
        //     this.setState({ isOpen: false })
        // }
        if (prevProps.value != this.props.value && this.props.value) {
            let value = this.props.value;
            let values = this.props.values;
            if (values && values.filter(item => item.value == value).length && values.filter(item => item.value == value)[0].name && this.state.valueText != values.filter(item => item.value == value)[0].name) {
                this.setState({ valueText: values.filter(item => item.value == value)[0].name })
            } else {
                this.setState({ valueText: this.props.value})
            }
        } else if (this.state.valueText) {
            this.setState({ valueText: null})
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        if (this.props.value) {
            let value = this.props.value;
            let values = this.props.values;
            if (values && values.filter(item => item.value == value).length && values.filter(item => item.value == value)[0].name && this.state.valueText != values.filter(item => item.value == value)[0].name) {
                this.setState({ valueText: values.filter(item => item.value == value)[0].name })
            } else {
                this.setState({ valueText: this.props.value })
            }
        } else if (this.state.valueText) {
            this.setState({ valueText: null })
        }

    }
    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {
     
            
        return (
            <FormGroup className={`position-relative ${this.props.additionalClassName}`} >
                <div ref={this.wrapperRef}>
                    <Label>{this.props.label}</Label>

                    <input autoComplete="off" id={this.props.inputID ? this.props.inputID : this.state.inputID} className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={this.props.textType ? this.props.textType : "text"}   //(this.props.touched && this.props.error) || (this.props.value && this.props.values && this.props.values.filter(item => item.value == this.props.value).length == 0)
                        ref={(node) => {
                            if (this.props.setFieldRef)
                                this.props.setFieldRef(node)
                        }}
                        onKeyUp={(e) => {
                            if (e.key == this.props.finishKey) {
                                this.setState({ isOpen: false })
                                this.props.focusNext();
                            }
                        }}
                        onChange={(e) => {
                            // if(!this.props.disabled){
                            if (e.target.value) {
                                this.props.onChange(e.target.value)
                            } else {
                                this.props.onChange(null)
                            }

                            this.setState({ isOpen: true })
                            // }
                        }}
                        onFocus={() => this.setState({ isOpen: true })}
                        disabled={this.props.readOnly}
                        value={this.state.valueText}
                        placeholder={this.props.placeholder ? this.props.placeholder : this.props.values && this.props.values.filter(item => item.value == -1).length ? this.props.values.filter(item => item.value == -1)[0].name : null}
                    ></input>
                    {
                        this.props.values && this.props.values.length && this.state.isOpen ?
                            <div className="select-with-search-list-wrap">
                                {
                                    this.props.values.map((item, idx) => {
                                        if (item && item.value != -1 && (!this.props.value || (this.props.value && (String(item.name).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1 || (!this.props.filterOnlyByName && String(item.value).toLowerCase().indexOf(this.props.value.toLowerCase()) != -1)))))
                                            return (
                                                <div id={`${this.state.inputID}-${idx}`} className="select-with-search-list-item" onClick={() => {
                                                    this.props.onChange(item.value)
                                                    this.setState({ isOpen: false }, () => {
                                                        document.getElementById(this.state.inputID).focus();
                                                        this.setState({ isOpen: false })
                                                    })
                                                }}>
                                                    {item.name}
                                                </div>
                                            )
                                    })
                                }

                            </div>
                            :
                            null
                    }
                    {this.props.error && this.props.touched ?
                        <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                        :

                        null
                    }

                </div>

            </FormGroup>
        )
    }
}

export default Text;
