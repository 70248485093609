import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Container, Row, Col, Input } from 'reactstrap';

// import ProfileIcon from '../assets/profile.svg'
import ArrowDownIcon from '../assets/arrow-down.svg'
import SettingsIcon from '../assets/settings.svg'
import SearchIcon from '../assets/search.svg'
import NotificationIcon from '../assets/notification.svg'

import { Link, Redirect } from 'react-router-dom';

import ProfileIcon from '../assets/profile-icon.svg'
import CartIcon from '../assets/cart.svg'

import logoutIcon from '../assets/logout.svg';

import SelectWithSearch from '../form-builder/fields/selectWithSearch'

import moment from 'moment';

import baner1 from '../assets/baner1.png';
import baner3 from '../assets/baner3.png';

import env from "react-dotenv";

import { parseJSON } from "../helpers/json";

import DeleteModal from './deleteModal'



function numberWithCommas(x, noZeros) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
        this.wrapperNotificationRef = React.createRef();
        this.state = {
            name: '',
            isOpen: false,
            isOpenNotifcations: false,
            orderItems: []
        }
    }

    componentDidMount() {
        this.checkName()

        document.addEventListener('mousedown', this.handleClickOutside);

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.uData != this.props.uData) {
            this.checkName()
        }
        if (prevProps.cartLastUpdate != this.props.cartLastUpdate) {
            this.getOrder()
        }
        if (prevProps.activeOrder != this.props.activeOrder || (this.state.orderItems.length == 0 && this.props.activeOrder)) {
            this.getOrder()
        }


    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    checkName = () => {
        let name = '';
        if (this.props.uData) {

            if (this.props.uData.businessUnitName) {
                name = this.props.uData.businessUnitName;
            } else if (this.props.uData.clientName) {
                name = this.props.uData.clientName;
            } else if (this.props.uData.username) {
                name = this.props.uData.username;
            }

        }
        this.setState({ name })
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
        if (this.wrapperNotificationRef && this.wrapperNotificationRef.current && !this.wrapperNotificationRef.current.contains(event.target)) {
            this.setState({ isOpenNotifcations: false })
        }

    }

    getOrder = () => {
        if (this.props.activeOrder) {

            fetch(env.API_URL + '/data/orders/get/order', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ orderId: this.props.activeOrder })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300) {
                    if (result && result.length) {
                        this.setState({ orderItems: result })
                    } else {
                        this.props.changeActiveOrder(null)
                        if (this.state.orderItems.length != 0)
                            this.setState({ orderItems: [] })
                    }
                }

            })

        } else {
            if (this.state.orderItems.length != 0)
                this.setState({ orderItems: [] })
        }
    }

    render() {

        return (
            <header className="header">
                <Container fluid style={{ padding: 0 }}>
                    <div className="header-container">
                        <div className='header-item-wrap'>
                            <div className='cart-wrap'>
                                <div className='icon-box cursor-pointer' onClick={() => {
                                    if (this.props[0].location.pathname != '/')
                                        this.props[0].history.push('/')
                                }}>
                                    <Isvg src={CartIcon} />
                                </div>
                                <div className='cart-info'>
                                    <h5>UKUPNO KM</h5>
                                    <h4>
                                        {
                                            this.state.orderItems && this.state.orderItems.length != 0 ?

                                                numberWithCommas(this.state.orderItems.reduce((sum, current) => {
                                                    let res;
                                                    res = sum + Number(current.sum);
                                                    return res
                                                }, 0))
                                                :
                                                '0,00'
                                        }
                                    </h4>
                                </div>
                            </div>
                            <div className="profile">
                                <div className="icon-box notifications-header-wrap" ref={this.wrapperNotificationRef} onClick={(e) => {
                                    this.setState({ isOpenNotifcations: true }, () => {
                                        if (this.props.unseenNotifications) {
                                            this.props.seenNotifications()
                                        }
                                    })

                                }}>
                                    <Isvg src={NotificationIcon} />
                                    {
                                        this.props.unseenNotifications ?
                                            <div className="notification-number"><p>{this.props.unseenNotifications}</p></div>
                                            :
                                            null
                                    }
                                    {
                                        this.state.isOpenNotifcations ?
                                            <div className={`notifications-dropdown-wrap`}>
                                                <div className='notification-header'>
                                                    <h6>Notifikacije</h6>
                                                    {
                                                        this.props.notifications && this.props.notifications.length ?
                                                            <div className='delete-notifications-div' onClick={() => {
                                                                this.setState({ deleteNotificationModal: true })
                                                            }}>
                                                                Obriši sve
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className='notifiction-dropdown-content'>
                                                    {
                                                        this.props.notifications && this.props.notifications.map((item, idx) => {
                                                            return (
                                                                <div className={`notification-item ${!item.clicked ? 'not-clicked' : ''}`} onClick={() => {
                                                                    if (item.clicked) {
                                                                        if (item.link) {
                                                                            this.setState({ isOpenNotifcations: false }, () => {
                                                                                this.props[0].history.push(item.link)
                                                                            })

                                                                        }
                                                                    } else {
                                                                        this.props.clickedNotification(item._id, () => {
                                                                            if (item.link) {
                                                                                this.setState({ isOpenNotifcations: false }, () => {
                                                                                    this.props[0].history.push(item.link)
                                                                                })
                                                                            }
                                                                        })
                                                                    }

                                                                }}>
                                                                    {
                                                                        item.title ?
                                                                            <h6>{item.title}</h6>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className='notifcation-description'>
                                                                        <p>{item.subtitle ? item.subtitle : ''}</p>
                                                                        {
                                                                            item.timestamp ?
                                                                                <div className='notif-date-time'>{moment.unix(item.timestamp).format('DD.MM.YYYY HH:mm')} h</div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        !this.props.notifications || (this.props.notifications && !this.props.notifications.length) ?
                                                            <div className='notification-item cursor-default'>
                                                                <h6 style={{ fontStyle: 'italic' }}>Nemate nijednu notifikaciju</h6>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <div className="icon-box ml-15">
                                    <Isvg src={ProfileIcon} />
                                </div>
                                <div className='profile-name'>
                                    <h6>{this.state.name}</h6>
                                </div>

                                <div className="profile-settings">
                                    <div className='dropdown-wrap' ref={this.wrapperRef} onClick={() => {
                                        this.setState({ isOpen: true })
                                    }}>
                                        <Isvg src={ArrowDownIcon} />
                                        {
                                            this.state.isOpen ?
                                                <div className='dropdown'>
                                                    <div className='dropdown-list-item' onClick={() => this.props.signOut(() => this.props[0].history.push('/login'))}>
                                                        <Isvg src={logoutIcon} />
                                                        <p>Logout</p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <span className="line"></span>
                                    <div className='settings-wrap'>
                                        <Isvg src={SettingsIcon} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='header-banner-wrap'>

                        {
                            this.props.banners && this.props.banners.bannerHeader1 && this.props.banners.bannerHeader1.image ?
                                <div className='first-banner-header'
                                    style={this.props.banners.bannerHeader1.link ? { cursor: 'pointer' } : []}
                                    onClick={() => {
                                        if (this.props.banners.bannerHeader1.link && typeof window != 'undefined') {
                                            window.open(this.props.banners.bannerHeader1.link, '_blank');
                                        }
                                    }}
                                >
                                    {/* <img src={this.props.banners.bannerHeader1.image} /> */}
                                    <img src={baner1} />
                                </div>
                                :
                                null
                        }
                        {
                            this.props.banners && this.props.banners.bannerHeader2 && this.props.banners.bannerHeader2.image ?
                                <div className='second-banner-header'
                                    style={this.props.banners.bannerHeader2.link ? { cursor: 'pointer' } : []}
                                    onClick={() => {
                                        if (this.props.banners.bannerHeader2.link && typeof window != 'undefined') {
                                            window.open(this.props.banners.bannerHeader2.link, '_blank');
                                        }
                                    }}>
                                    {/* <img src={this.props.banners.bannerHeader2.image} /> */}
                                    <img src={baner3} />
                                </div>
                                :
                                null
                        }
                        {/* <div className='second-banner-header'>
                            <a>
                                <img src={baner3} />
                            </a>
                        </div> */}
                    </div>
                </Container>


                <DeleteModal
                    isOpen={this.state.deleteNotificationModal}
                    toggle={() => this.setState({ deleteNotificationModal: null })}
                    handler={() => {
                        this.setState({ deleteNotificationModal: null }, () => {
                            this.props.deleteAllNotifications()
                        })

                    }} >
                    Da li želite obrisati sve notifikacije?
                </DeleteModal>
            </header>
        )
    }
}

export default Header;
