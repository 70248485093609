import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';

import DateField from '../../form-builder/fields/date';

import PrintHelper from '../../components/printHelper';

import garbageOpionIcon from '../../assets/garbage-option.svg';
import env from "react-dotenv";


import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function dynamicSort(property, sortOrder) {
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            reservation: false,
            orderItems: []
        }
    }
    get = () => {

        this._onLoad()


    }
    componentDidMount() {
        this.get()



    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }



    }
    deleteOrder = (item) => {
        if (item && item._id) {
            this._apiAsync('delete-order', { _id: item._id }).then((res) => {
                this.get()
                this.props.cartUpdated()
                this.setState({ deleteModal: false })
            })
        }

    }


    render() {
        let params = this._getParams();
        return (
            <div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>

                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 20, marginTop: 200 }} id="left-table" >
                            <div className='section-table-title'>
                                <h4>Narudžbe u pripremi</h4>
                            </div>
                            <div className='left-table-mt'>

                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13 } },
                                        { type: 'text', name: 'userData.orderNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 }, substr: 15 },
                                        { type: 'text', name: 'numberOfItems', label: 'BR. ARTIKALA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'tsModified', label: 'POSLEDNJA IZMJENA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', numberWithCommas: true },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                            backgroundClassname: item._id == this.props.activeOrder ? 'active-order-row' : '',
                                            tsModified: item.tsModified ? this.props.getDateStringFromTs(item.tsModified, 'DD.MM.YYYY HH:mm') : item.tsCreated ? this.props.getDateStringFromTs(item.tsCreated, 'DD.MM.YYYY HH:mm') : ''
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={editIcon} className="edit-icon" />,
                                                onClick: (item) => {
                                                    this.props.changeActiveOrder(item._id)
                                                    this.props[0].history.push('/')
                                                },
                                                condition: (item) => {
                                                    if (item._id == this.props.activeOrder)
                                                        return false
                                                    else
                                                        return true
                                                }
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    rawItems={this.state.items}

                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </Col>



                    </Row>


                </Container>
                <DeleteModal
                    isOpen={this.state.deleteModal}
                    toggle={() => this.setState({ deleteModal: null })}
                    handler={() => {

                        this.deleteOrder(this.state.deleteModal)
                    }} >
                    Da li želite obrisati narudžbu <strong>{this.state.deleteModal && this.state.deleteModal.userData ? this.state.deleteModal.userData.orderNumber : ''}</strong> ?
                </DeleteModal>
            </div >
        )
    }
}

export default Page(StockPage);