import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import InfoModal from '../../components/infoModal';
import ErrorModal from '../../components/errorModal';

import Checkbox from '../../form-builder/fields/checkbox';

import DateField from '../../form-builder/fields/date';

import PrintHelper from '../../components/printHelper';

import garbageOpionIcon from '../../assets/garbage-option.svg';

import CartIcon from '../../assets/cart.svg'

import env from "react-dotenv";

import SelectWithSearch from '../../form-builder/fields/selectWithSearch';

import { Player, Controls } from "@lottiefiles/react-lottie-player";




import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';

function numberWithCommas(x, noZeros) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}

function dynamicSort(property, sortOrder) {
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

class HomePage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            reservation: false,
            orderItems: [],
            manufacturers: [],
        }
    }
    get = (reset = false) => {

        let multipleParams = [
            { name: 'article', value: this.state.filterArticle },
            { name: 'manufacturer', value: this.state.filterManufacturer },
        ]
        if (reset) {
            multipleParams.push({ name: 'page', value: 0 })
        }


        this.updateMultipleParams(multipleParams)


        this._onLoad();


    }
    componentDidMount() {

        this.get()

        // if (typeof window != 'undefined' && localStorage.order) {
        //     let orderItems = JSON.parse(localStorage.order)
        //     if (orderItems.length) {
        //         this.setState({ orderItems }, () => this.checkOrderItemsAmounts())
        //     }
        // }

        this.checkParams()


        this._apiAsync('get-manufacturers', {}).then((res) => {
            if (res) {
                this.setState({ manufacturers: res })
            }
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this.get()
        }
        if (JSON.stringify(prevState.items) != JSON.stringify(this.state.items)) {
            this.checkOrderItemsAmounts()
        }
        if (prevProps.cartLastUpdate != this.props.cartLastUpdate) {
            this.saveOrder()
        }
        if (prevProps.activeOrder != this.props.activeOrder || (this.state.orderItems.length == 0 && this.props.activeOrder)) {
            this.getOrder()
        }





    }
    checkOrderItemsAmounts = () => {


        if (this.state.orderItems && this.state.items) {
            let items = this.state.items;
            for (let i = 0; i < items.length; i++) {
                items[i].ammount = '';
            }

            for (let i = 0; i < items.length; i++) {
                let check = this.state.orderItems.filter(el => el.stockId == items[i]._id);

                if (check && check.length) {
                    for (let j = 0; j < check.length; j++) {
                        if (!items[i].ammount) {
                            items[i].ammount = 0;
                        }
                        items[i].ammount += Number(check[j].ammount)
                    }
                }
            }
            this.setState({ items })

        }
    }


    checkParams = (get = false) => {
        let obj = {}
        let params = this._getParams()
        if (!this.state.filterArticle && params.article) {
            obj.filterArticle = params.article;
        }
        if (!this.state.filterManufacturer && params.manufacturer) {
            obj.filterManufacturer = params.manufacturer;
        }

    }
    clearOrder = () => {
        if (this.state.orderItems) {
            this.setState({ orderItems: [] }, () => {
                this.checkOrderItemsAmounts()
                // if (typeof window != 'undefined' && localStorage.order) {
                //     localStorage.removeItem('order')
                // }
            })
        }
        this.setState({
            filterManufacturer: '',
            filterArticle: '',
        }, () => {
            this.updateMultipleParams(
                [
                    { name: 'manufacturer', value: null },
                    { name: 'article', value: null },
                ])
        })
    }
    updateOrderItems = (field, type) => {
        let sortOrder = this.state.sortOrder;

        if (sortOrder && sortOrder.field == field) {
            if (sortOrder.type == 1) {
                type = -1;
            } else {
                type = 1;
            }
        }
        let orderItems = this.state.orderItems.map(item => {
            return {
                ...item,
                ammount: Number(item.ammount)
            }
        });
        orderItems.sort(dynamicSort(field, type));
        this.setState({ orderItems, sortOrder: { field, type } })
    }
    // deleteFromOrder = (item) => {
    //     this.setState({ deleteModal: null })
    //     let orderItems = this.state.orderItems;
    //     if (orderItems && orderItems.length && item && item.stockId) {
    //         let index = orderItems.findIndex(el => el.stockId == item.stockId)

    //         if (index > -1) {
    //             this.setState({ successAdded: null }, () => this.setState({
    //                 successAdded: item.stockId
    //             }))
    //             orderItems.splice(index, 1)
    //             this.setState({ orderItems }, () => {
    //                 this.saveOrder(true)
    //                 this.checkOrderItemsAmounts()
    //                 this.props.cartUpdated()
    //                 // if (typeof window != 'undefined') {
    //                 //     localStorage.setItem('order', JSON.stringify(this.state.orderItems))
    //                 // }
    //             })
    //         }
    //     }


    // }
    deleteFromOrder = (stockId) => {
        this.setState({ deleteModal: null })
        let orderItems = this.state.orderItems;

        if (orderItems && orderItems.length && stockId) {
            let loading = false;
            // if (orderItems.filter(el => el.stockId == stockId).length) {
            //     orderItems = [...orderItems.filter(el => el.stockId != stockId)];
            //     loading = true;
            // }
            let ids = [];
            for (let i = 0; i < orderItems.length; i++) {
                if (orderItems[i].stockId == stockId) {
                    loading = true;
                    ids.push(i)
                    // orderItems.splice(i, 1)
                }
            }
            ids.reverse()
            if (ids && ids.length) {
                for (let i = 0; i < ids.length; i++) {
                    if (orderItems[ids[i]]) {
                        orderItems.splice(ids[i], 1)
                    }
                }
            }
            if (loading) {
                this.setState({ successAdded: null }, () => this.setState({
                    successAdded: stockId
                }))
            }
            this.setState({ orderItems }, () => {
                this.saveOrder(true)
                this.checkOrderItemsAmounts()
                this.props.cartUpdated()

            })
        }
    }
    deleteFromOrderByIdx = (stockId, idx) => {
        this.setState({ deleteModal: null })
        let orderItems = this.state.orderItems;
        if (orderItems && orderItems[idx] && orderItems[idx].stockId == stockId) {
            this.setState({ successAdded: null }, () => this.setState({
                successAdded: stockId
            }))
            orderItems.splice(idx, 1)
            console.log(orderItems)
            this.setState({ orderItems }, () => {
                this.saveOrder(true)
                this.checkOrderItemsAmounts()
                this.props.cartUpdated()

            })

        }
    }
    finishOrder = () => {
        if (this.state.orderItems && this.state.orderItems.length) {
            let obj = { items: this.state.orderItems, finished: true }
            if (this.props.activeOrder) {
                obj._id = this.props.activeOrder;
            }
            if (this.state.note) {
                obj.note = this.state.note;
            }

            this.props.changeActiveOrder(null)

            this._apiAsync('save-order', obj).then((res) => {
                if (res && !res.error) {
                    this.setState({ infoModal: 'Narudžba je uspješno poslata!' }, () => this.clearOrder())
                }
                this.setState({ note: null })
            })
        }
    }
    saveOrder = (emptyArray) => {

        let check = false;
        if (emptyArray) {
            check = true;
        }
        if (this.state.orderItems && this.state.orderItems.length) {
            check = true;
        }
        if (check) {
            let obj = { items: this.state.orderItems }
            if (this.props.activeOrder) {
                obj._id = this.props.activeOrder;
            }
            this._apiAsync('save-order', obj).then((res) => {
                if (res && res._id) {
                    this.props.changeActiveOrder(res._id)

                }
            })
        }
    }
    getOrder = () => {
        if (this.props.activeOrder) {
            this._apiAsync('get-order', { orderId: this.props.activeOrder }).then((res) => {
                if (res && res.length) {
                    this.setState({ orderItems: res }, () => {
                        this.checkOrderItemsAmounts()
                    })
                } else {
                    this.props.changeActiveOrder(null)
                    if (this.state.orderItems.length != 0)
                        this.setState({ orderItems: [] }, () => {
                            this.checkOrderItemsAmounts()
                        })
                }
            })
        } else {
            this.setState({ orderItems: [] }, () => {
                this.checkOrderItemsAmounts()
            })
        }
    }
    checkQuantity = async (stockId) => {
        let returnValue = {
            currentQuantity: 0,
            series: []
        };

        let check = await this._apiAsync('check-quantity', { id: stockId })
        if (check && check.currentQuantity) {
            returnValue.currentQuantity = Number(check.currentQuantity);
        }
        if (check && check.series) {
            returnValue.series = check.series;
        }

        return returnValue;
    }


    render() {
        let params = this._getParams();


        return (
            <div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>

                        <Col lg="5" style={{ paddingLeft: 0, paddingRight: 20, marginTop: 130 }} id="left-table" >
                            <div className='section-table-title'>
                                <h4>Kreiranje narudžbe</h4>
                                {
                                    this.state.orderItems && this.state.orderItems.length && this.props.activeOrder ?
                                        <div className='right-table-header'>
                                            <Button onClick={() => {
                                                this.setState({ infoModal3: 'Da li želite započeti novu nardžbu?' })
                                            }}>
                                                <div className='icon-box'>
                                                    <Isvg src={editIcon} />
                                                </div>
                                                ZAPOČNI NOVU NARUDŽBU
                                            </Button>
                                        </div>
                                        :
                                        null
                                }

                            </div>

                            <div className='left-table-mt'>
                                <div className="list-builder-filters-wrap">


                                    <div className="list-builder-filters" >
                                        <div className="field-strap-wrap">
                                            <Label>Proizvod ili barkod</Label>
                                            <Input type='text' value={this.state.filterArticle} onChange={(e) => this.setState({ filterArticle: e.target.value }, () => {
                                                this.get(true)
                                            })} />
                                        </div>
                                        <div className="field-strap-wrap" >
                                            <SelectWithSearch
                                                finishKey={'Enter'}
                                                onChange={(value) => {
                                                    this.setState({ filterManufacturer: value }, () => {
                                                        if (this.state.manufacturers && this.state.manufacturers.filter(el => el.name == value).length) {
                                                            this.get(true)
                                                        } else if (!value) {
                                                            this.get(true)
                                                        }
                                                    })
                                                    // this.onChange(item.name, value, item.asyncValidation)
                                                }}
                                                value={this.state.filterManufacturer}
                                                label={'Proizvođači'}
                                                showName={true}
                                                style={{ width: '100%' }}
                                                values={this.state.manufacturers.filter(el => this.state.filterManufacturer && el.name && el.name.toLowerCase().indexOf(this.state.filterManufacturer.toLowerCase()) != -1).map((item, idx) => {
                                                    let obj = {
                                                        value: item.name,
                                                        name: item.name
                                                    }
                                                    return obj
                                                })}
                                            ></SelectWithSearch>

                                            {/* <Label>Proizvođači</Label>
                                            <Input type='text' value={this.state.filterManufacturer} onChange={(e) => this.setState({ filterManufacturer: e.target.value }, () => {
                                                this.get()
                                            })} /> */}
                                        </div>



                                    </div>


                                </div>
                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { maxWidth: '40%', wordBreak: 'break-all' } },
                                        { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'ammount', label: 'KOLIČINA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset' },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                            quantity: item.quantity - item.quantityReserved,
                                            barCodes: item.barcode && item.barcode.length ? item.barcode.join(', ') : '',
                                            productName: <div className='list-builder-product-name-wrap'>
                                                <h6>{item.productName}</h6>
                                                {
                                                    item.barcode && item.barcode.map((barcode, bidx) => {
                                                        return (
                                                            <p>{barcode}</p>
                                                        )
                                                    })
                                                }
                                            </div>,
                                            ammount: <div className='order-column-wrap'>
                                                <Input id={`input-${item._id}`} type='text'
                                                    onKeyUp={async (e) => {

                                                        if (e.key == 'Enter') {
                                                            let value = item.ammount;

                                                            let checkOnStock = Number(item.quantity) - Number(item.quantityReserved);

                                                            let checkProduct = await this.checkQuantity(item._id);
                                                            console.log(checkProduct)
                                                            let onStock = 0;
                                                            if (checkProduct.currentQuantity) {
                                                                onStock = checkProduct.currentQuantity;
                                                            }
                                                            item.series = checkProduct.series;
                                                            let orderItems = this.state.orderItems;

                                                            let check = orderItems.filter(el => el.stockId == item._id)
                                                            if (check && check.length) {
                                                                let checkAmmount = 0;
                                                                for (let i = 0; i < check.length; i++) {
                                                                    if (check[i] && check[i].ammount) {
                                                                        checkAmmount += check[i].ammount;
                                                                    }
                                                                }
                                                                onStock += checkAmmount;
                                                            }
                                                            if (item.series && item.series.length) {
                                                                for (let i = 0; i < item.series.length; i++) {
                                                                    let indexSeries = orderItems.findIndex(el => String(el.serialNumber) == String(item.series[i].serialNumber))
                                                                    if (indexSeries > -1) {
                                                                        item.series[i].ammount += orderItems[indexSeries].ammount;
                                                                    }
                                                                }
                                                            }


                                                            if (Number(value) > onStock) {
                                                                value = onStock;
                                                                if (onStock) {
                                                                    this.setState({ error: `Na lageru ima samo ${onStock}!` })
                                                                } else {
                                                                    this.setState({ error: `Artikal nije na lageru!` })
                                                                }
                                                                let items = this.state.items;
                                                                if (items && items[idx] && items[idx].ammount) {
                                                                    items[idx].ammount = value;
                                                                }
                                                                this.setState({ items }, this.checkOrderItemsAmounts)
                                                                // return;
                                                            }

                                                            if (value && Number(value) > 0) {
                                                                this.setState({ successAdded: null }, () => this.setState({
                                                                    successAdded: item._id
                                                                }))

                                                                let newArr = []
                                                                for (let i = 0; i < orderItems.length; i++) {
                                                                    if (orderItems[i].stockId != item._id) {
                                                                        newArr.push(orderItems[i])
                                                                    }
                                                                }
                                                                orderItems = [...newArr]
                                                                let newValue = Number(value)
                                                                if (item) {
                                                                    if (item.series && item.series.length) {
                                                                        for (let i = 0; i < item.series.length; i++) {
                                                                            if (newValue > 0) {

                                                                                if (newValue <= Number(item.series[i].ammount)) {
                                                                                    let obj = {
                                                                                        productCode: item.productCode,
                                                                                        productName: item.productName,
                                                                                        price: item.sellPrice,
                                                                                        stockId: item._id,
                                                                                        ammount: newValue,
                                                                                        sum: Number(item.sellPrice) * Number(value),
                                                                                        manufacturerCode: item.manufacturerCode,
                                                                                        manufacturerName: item.manufacturerName,
                                                                                        serialNumber: item.series[i].serialNumber,
                                                                                        deadline: item.series[i].expirationDate
                                                                                    }
                                                                                    newValue -= newValue;
                                                                                    orderItems.push(obj);
                                                                                } else if (newValue > Number(item.series[i].ammount)) {
                                                                                    let obj = {
                                                                                        productCode: item.productCode,
                                                                                        productName: item.productName,
                                                                                        price: item.sellPrice,
                                                                                        stockId: item._id,
                                                                                        ammount: Number(item.series[i].ammount),
                                                                                        sum: Number(item.sellPrice) * Number(value),
                                                                                        manufacturerCode: item.manufacturerCode,
                                                                                        manufacturerName: item.manufacturerName,
                                                                                        serialNumber: item.series[i].serialNumber,
                                                                                        deadline: item.series[i].expirationDate
                                                                                    }
                                                                                    newValue -= Number(item.series[i].ammount);
                                                                                    orderItems.push(obj);
                                                                                }

                                                                            }
                                                                        }
                                                                        if (newValue > 0) {
                                                                            let obj = {
                                                                                productCode: item.productCode,
                                                                                productName: item.productName,
                                                                                price: item.sellPrice,
                                                                                stockId: item._id,
                                                                                ammount: newValue,
                                                                                sum: Number(item.sellPrice) * Number(value),
                                                                                manufacturerCode: item.manufacturerCode,
                                                                                manufacturerName: item.manufacturerName,

                                                                            }
                                                                            orderItems.push(obj);
                                                                        }
                                                                    } else {
                                                                        let obj = {
                                                                            productCode: item.productCode,
                                                                            productName: item.productName,
                                                                            price: item.sellPrice,
                                                                            stockId: item._id,
                                                                            ammount: newValue,
                                                                            sum: Number(item.sellPrice) * Number(value),
                                                                            manufacturerCode: item.manufacturerCode,
                                                                            manufacturerName: item.manufacturerName,
                                                                            serialNumber: null,
                                                                            deadline: null
                                                                        }
                                                                        orderItems.push(obj);
                                                                    }

                                                                }


                                                            } else {
                                                                if (item && item._id)
                                                                    this.deleteFromOrder(item._id)
                                                            }
                                                            this.setState({ orderItems }, () => {
                                                                this.props.cartUpdated()

                                                            })
                                                            if (typeof window != 'undefined') {
                                                                document.getElementById(`input-${item._id}`).blur()
                                                                let index = this.state.items.findIndex(el => el._id == item._id);
                                                                let nextIndex;
                                                                if (index > -1) {
                                                                    nextIndex = index + 1;
                                                                }
                                                                if (nextIndex > -1 && this.state.items[nextIndex] && this.state.items[nextIndex]._id) {
                                                                    document.getElementById(`input-${this.state.items[nextIndex]._id}`).focus()
                                                                }

                                                            }

                                                            if (checkOnStock != onStock) {
                                                                this.get()
                                                            }
                                                        }
                                                    }}
                                                    value={item.ammount}
                                                    onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (/^[0-9]*$/i.test(value)) {
                                                            let items = this.state.items;
                                                            if (items && items[idx]) {
                                                                if (value.length) {
                                                                    items[idx].ammount = Number(value);
                                                                } else {
                                                                    items[idx].ammount = value;
                                                                }

                                                            }
                                                            this.setState({ items })
                                                        }

                                                    }} />


                                                <Button onClick={async () => {
                                                    let value = item.ammount;

                                                    let checkOnStock = Number(item.quantity) - Number(item.quantityReserved);

                                                    let checkProduct = await this.checkQuantity(item._id);
                                                    let onStock = 0;
                                                    if (checkProduct.currentQuantity) {
                                                        onStock = checkProduct.currentQuantity;
                                                    }
                                                    item.series = checkProduct.series;

                                                    let orderItems = this.state.orderItems;
                                                    // let index = orderItems.findIndex(el => el.stockId == item._id);


                                                    let check = orderItems.filter(el => el.stockId == item._id)
                                                    if (check && check.length) {
                                                        let checkAmmount = 0;
                                                        for (let i = 0; i < check.length; i++) {
                                                            if (check[i] && check[i].ammount) {
                                                                checkAmmount += check[i].ammount;
                                                            }
                                                        }
                                                        onStock += checkAmmount;
                                                    }
                                                    if (item.series && item.series.length) {
                                                        for (let i = 0; i < item.series.length; i++) {
                                                            let indexSeries = orderItems.findIndex(el => String(el.serialNumber) == String(item.series[i].serialNumber))
                                                            if (indexSeries > -1) {
                                                                item.series[i].ammount += orderItems[indexSeries].ammount;
                                                            }
                                                        }
                                                    }


                                                    if (Number(value) > onStock) {
                                                        value = onStock;
                                                        if (onStock) {
                                                            this.setState({ error: `Na lageru ima samo ${onStock}!` })
                                                        } else {
                                                            this.setState({ error: `Artikal nije na lageru!` })
                                                        }
                                                        let items = this.state.items;
                                                        if (items && items[idx] && items[idx].ammount) {
                                                            items[idx].ammount = value;
                                                        }
                                                        this.setState({ items }, this.checkOrderItemsAmounts)
                                                        // return;
                                                    }

                                                    if (value && Number(value) > 0) {
                                                        this.setState({ successAdded: null }, () => this.setState({
                                                            successAdded: item._id
                                                        }))
                                                        let newArr = []
                                                        for (let i = 0; i < orderItems.length; i++) {
                                                            if (orderItems[i].stockId != item._id) {
                                                                newArr.push(orderItems[i])
                                                            }
                                                        }
                                                        orderItems = [...newArr]

                                                        let newValue = Number(value)
                                                        if (item) {
                                                            if (item.series && item.series.length) {
                                                                for (let i = 0; i < item.series.length; i++) {
                                                                    if (newValue > 0) {

                                                                        if (newValue <= Number(item.series[i].ammount)) {
                                                                            let obj = {
                                                                                productCode: item.productCode,
                                                                                productName: item.productName,
                                                                                price: item.sellPrice,
                                                                                stockId: item._id,
                                                                                ammount: newValue,
                                                                                sum: Number(item.sellPrice) * Number(value),
                                                                                manufacturerCode: item.manufacturerCode,
                                                                                manufacturerName: item.manufacturerName,
                                                                                serialNumber: item.series[i].serialNumber,
                                                                                deadline: item.series[i].expirationDate
                                                                            }
                                                                            newValue -= newValue;
                                                                            orderItems.push(obj);
                                                                        } else if (newValue > Number(item.series[i].ammount)) {
                                                                            let obj = {
                                                                                productCode: item.productCode,
                                                                                productName: item.productName,
                                                                                price: item.sellPrice,
                                                                                stockId: item._id,
                                                                                ammount: Number(item.series[i].ammount),
                                                                                sum: Number(item.sellPrice) * Number(value),
                                                                                manufacturerCode: item.manufacturerCode,
                                                                                manufacturerName: item.manufacturerName,
                                                                                serialNumber: item.series[i].serialNumber,
                                                                                deadline: item.series[i].expirationDate
                                                                            }
                                                                            newValue -= Number(item.series[i].ammount);
                                                                            orderItems.push(obj);
                                                                        }

                                                                    }
                                                                }
                                                                if (newValue > 0) {
                                                                    let obj = {
                                                                        productCode: item.productCode,
                                                                        productName: item.productName,
                                                                        price: item.sellPrice,
                                                                        stockId: item._id,
                                                                        ammount: newValue,
                                                                        sum: Number(item.sellPrice) * Number(value),
                                                                        manufacturerCode: item.manufacturerCode,
                                                                        manufacturerName: item.manufacturerName,

                                                                    }
                                                                    orderItems.push(obj);
                                                                }
                                                            } else {
                                                                let obj = {
                                                                    productCode: item.productCode,
                                                                    productName: item.productName,
                                                                    price: item.sellPrice,
                                                                    stockId: item._id,
                                                                    ammount: newValue,
                                                                    sum: Number(item.sellPrice) * Number(value),
                                                                    manufacturerCode: item.manufacturerCode,
                                                                    manufacturerName: item.manufacturerName,
                                                                    serialNumber: null,
                                                                    deadline: null
                                                                }
                                                                orderItems.push(obj);
                                                            }

                                                        }




                                                    } else {
                                                        if (item && item._id)
                                                            this.deleteFromOrder(item._id)
                                                    }

                                                    this.setState({ orderItems }, () => {
                                                        this.props.cartUpdated()

                                                    })
                                                    if (checkOnStock != onStock) {
                                                        this.get()
                                                    }

                                                }}>
                                                    <Isvg src={CartIcon} />
                                                </Button>

                                                {
                                                    this.state.successAdded && this.state.successAdded == item._id ?
                                                        <div className='success-added-loader'>
                                                            <Player
                                                                autoplay={true}
                                                                loop={false}
                                                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                                                style={{ height: "36px", width: "36px" }}
                                                                keepLastFrame={true}
                                                            ></Player>

                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        }
                                    })}
                                    rawItems={this.state.items}
                                    actions={
                                        [

                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </Col>
                        <Col lg="7" style={{ paddingLeft: 0, paddingRight: 0, marginTop: 230 }} >
                            <div className='right-table-mt' style={{ maxHeight: document.getElementById('left-table') && document.getElementById('left-table').scrollHeight > 600 ? (document.getElementById('left-table').scrollHeight - 77) : 'unset', overflow: 'hidden', overflowY: 'auto' }}>

                                <div className='right-table-header'>
                                    {
                                        this.state.orderItems && this.state.orderItems.length && this.props.activeOrder ?
                                            <Button onClick={() => {
                                                // this.setState({ infoModal2: 'Da li želite nastaviti sa knjiženjem?' })
                                                this.setState({ finishOrderModal: true })
                                            }}>
                                                <div className='icon-box'>
                                                    <Isvg src={editIcon} />
                                                </div>
                                                PROKNJIŽI

                                            </Button>
                                            :
                                            null
                                    }
                                </div>

                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loadingOrder}
                                    total={this.state.total}
                                    hidePagination={true}
                                    showNumeration={false}
                                    fields={[

                                        { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13 } },
                                        { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'ammount', label: 'KOL'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 }, numberWithCommas: true },
                                        { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 }, numberWithCommas: true },

                                    ]}
                                    items={this.state.orderItems.map((item, idx) => {
                                        return {
                                            ...item,

                                        }
                                    })}
                                    rawItems={this.state.orderItems}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={garbageIcon} className="delete-icon" />,
                                                onClick: (item, idx) => {
                                                    this.setState({
                                                        deleteModal: {
                                                            item, idx
                                                        }
                                                    })

                                                },
                                            }
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateOrderItems}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </Col>


                    </Row>

                    <DeleteModal
                        isOpen={this.state.deleteModal}
                        toggle={() => this.setState({ deleteModal: null })}
                        handler={() => {

                            // this.deleteFromOrder(this.state.deleteModal)
                            if (this.state.deleteModal && this.state.deleteModal.item && this.state.deleteModal.item.stockId) {
                                this.deleteFromOrderByIdx(this.state.deleteModal.item.stockId, this.state.deleteModal.idx)

                            }
                        }} >
                        Da li želite obrisati narudžbu za artikal <strong>{this.state.deleteModal && this.state.deleteModal.item ? this.state.deleteModal.item.productName : ''}</strong> ?
                    </DeleteModal>
                    <InfoModal
                        isOpen={this.state.infoModal}
                        toggle={() => this.setState({ infoModal: null })}
                        info={this.state.infoModal}
                        buttons={[
                            {
                                text: 'Zatvori',
                                onClick: () => this.setState({ infoModal: null })
                            },

                        ]}
                    >
                    </InfoModal>

                    <ErrorModal
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null }, () => {
                            this.checkOrderItemsAmounts()
                        })}
                        error={this.state.error}

                    >
                    </ErrorModal>
                    <InfoModal
                        isOpen={this.state.infoModal2}
                        toggle={() => this.setState({ infoModal2: null })}
                        info={this.state.infoModal2}
                        buttons={[
                            {
                                text: 'Da',
                                onClick: () => {
                                    this.finishOrder()
                                    this.setState({ infoModal2: null })
                                }
                            },
                            {
                                text: 'Ne',
                                onClick: () => this.setState({ infoModal2: null })
                            },

                        ]}
                    >
                    </InfoModal>

                    <InfoModal
                        isOpen={this.state.infoModal3}
                        toggle={() => this.setState({ infoModal3: null })}
                        info={this.state.infoModal3}
                        buttons={[
                            {
                                text: 'Da',
                                onClick: () => {
                                    this.props.changeActiveOrder(null)
                                    this.setState({ orderItems: [] }, () => {
                                        this.checkOrderItemsAmounts()
                                    })
                                    this.setState({ infoModal3: null })
                                }
                            },
                            {
                                text: 'Ne',
                                onClick: () => this.setState({ infoModal3: null })
                            },

                        ]}
                    >
                    </InfoModal>

                    {
                        this.state.finishOrderModal ?
                            <Modal isOpen={this.state.finishOrderModal} size='lg' className="finish-order-modal-wrap">

                                <ModalBody className="finish-order-modal-body">
                                    <FormGroup>
                                        <Label>Dodaj napomenu</Label>
                                        <Input type='textarea' value={this.state.note} onChange={(e) => {
                                            this.setState({ note: e.target.value })
                                        }} />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color='primary' onClick={() => {
                                        this.finishOrder()
                                        this.setState({ finishOrderModal: null })
                                    }}>Potvrdi nardžbu</Button>
                                    <Button color='danger' onClick={() => {
                                        this.setState({ finishOrderModal: null, note: null })
                                    }}>Odustani</Button>
                                </ModalFooter>

                            </Modal>
                            :
                            null
                    }

                </Container >

            </div >
        )
    }
}

export default Page(HomePage);