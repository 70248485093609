import { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import FormBuilder from './form-builder';
import Routes from './routes';

import env from "react-dotenv";
import moment from 'moment';

moment.locale('sr')


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  //console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage.translate = JSON.stringify(obj);
     
     return this;*/

    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    //console.log(Object.keys(langs), lang)
    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.appInitialData,
      sidebarShort: false,
      userVerificationInProgress: true,
      uData: null,
      modulesTree: [],
      availablePaths: [],
      selectedYear: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY'),
      cartLastUpdate: Math.floor(new Date().getTime() / 1000),
      activeOrder: null,
      notifications: [],
      unseenNotifications: 0,
      banners: null
    }
  }

  cartUpdated = () => {
    this.setState({ cartLastUpdate: Math.floor(new Date().getTime() / 1000) })
  }

  selectYear = (year) => {
    this.setState({ selectedYear: year }, () => {
      if (typeof window != 'undefined') {
        localStorage.setItem('year', this.state.selectedYear);
      }
    })
  }
  updateMeta = () => {

  }
  verifyUser = (callback) => {
    if (!localStorage.getItem('authToken')) {
      this.setState({
        userVerificationInProgress: false
      })
      return;
    }

    fetch(env.API_URL + "/users/verify", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result)
        if (result.error) {
          this.setState({
            error: result.error
          })
        } else {

          this.setState({ uData: result }, () => {
            if (!this.state.banners)
              this.getBanners()
            if (callback) {
              callback()
            }


          })
        }
      });
  }
  getBanners = () => {

    fetch(env.API_URL + "/data/banners", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then((res) => res.json()).then((result) => {
      if (result && result.banners) {
        this.setState({ banners: result.banners })
      } else {
        this.setState({ banners: null })
      }
    });
  }

  getOriginalDateFormat(date) {
    let returnValue = '';
    let dateSplit = date.split('.');
    returnValue = dateSplit[1] + '-' + dateSplit[0] + '-' + dateSplit[2];

    return returnValue;
  }
  checkDateValid(date) {
    let dateValid = true;
    let dateSplit = date.split('.');
    if (dateSplit && dateSplit.length == 3) {
      if ((Number(dateSplit[0]) <= 31 && Number(dateSplit[0]) > 0) && (Number(dateSplit[1]) <= 12 && Number(dateSplit[1]) > 0) && (Number(dateSplit[2]) <= 2100 && Number(dateSplit[2]) > 1970)) {
        dateValid = true;
      } else {
        dateValid = false;
      }
    } else {
      dateValid = false;
    }
    return dateValid;
  }
  getDateStringFromTs(timestamp, format = 'DD.MM.YYYY') {
    let returnValue = moment.unix(timestamp).format(format);
    return returnValue;
  }
  getTsFromDateString(date) {
    let returnValue = date;
    let split = date.split('.');
    if (split && split.length == 3) {
      let englishDate = '';
      englishDate = split[1] + '-' + split[0] + '-' + split[2]
      returnValue = Math.floor(new Date(englishDate).getTime() / 1000);
    }
    return returnValue;
  }
  roundToTwoDecimals = (number, round = true, decimals = false) => {
    let result = Math.round(Number(number) * 100) / 100;
    if (round) {
      if (decimals) {
        let splitNumber = String(result).split('.');
        result = splitNumber[0] + ',';
        if (splitNumber[1]) {
          if (Number(splitNumber[1]) < 10) {
            result += splitNumber[1] + '0'
          } else {
            result += splitNumber[1]
          }
        } else {
          result += '00';
        }

      }
      return result;
    } else {
      return number;
    }

  }
  componentDidMount() {
    this.verifyUser()

    if (typeof window != 'undefined') {
      if (localStorage.year) {
        this.setState({ selectedYear: localStorage.year })
      } else if (this.state.selectedYear) {
        localStorage.setItem('year', this.state.selectedYear)
      }

      setTimeout(() => {
        if (localStorage.activeOrder) {
          this.setState({ activeOrder: localStorage.activeOrder })
        }
      }, 500);

    }

    this.getNotifications()

    this.notificationsInterval = setInterval(() => {
      this.getNotifications()
    }, 1000 * 30);


    this.getBanners()

  }
  componentWillUnmount() {
    if (this.notificationsInterval) {
      clearInterval(this.notificationsInterval)
    }
  }


  changeActiveOrder = (id) => {
    this.setState({ activeOrder: id }, () => {
      this.cartUpdated()
      if (typeof window != 'undefined') {
        if (this.state.activeOrder) {
          localStorage.activeOrder = id;
        } else {
          localStorage.removeItem('activeOrder')
        }

      }

    })
  }
  signOut = (callback) => {
    if (typeof window != 'undefined') {
      localStorage.removeItem('authToken');
      this.setState({ uData: null }, () => {
        if (callback) {
          callback()
        }
      })
    }
  }
  sidebarChange = () => {
    this.setState({
      sidebarShort: !this.state.sidebarShort
    })
  }



  getNotifications = () => {
    if (typeof window != 'undefined' && localStorage.getItem('authToken')) {
      fetch(env.API_URL + "/data/notifications", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then((res) => res.json()).then((result) => {
        if (result && result.items && result.items.length) {
          let unseenNotifications = 0;
          if (result.unseen) {
            unseenNotifications = result.unseen;
          }
          this.setState({ notifications: result.items, unseenNotifications: unseenNotifications })
        } else {
          this.setState({ notifications: [], unseenNotifications: 0 })
        }
      });
    }

  }
  seenNotifications = () => {

    fetch(env.API_URL + "/data/notifications/seen", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then((res) => res.json()).then((result) => {
      this.getNotifications()
    });
  }
  deleteAllNotifications = () => {

    fetch(env.API_URL + "/data/notifications/delete-all", {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then((res) => res.json()).then((result) => {
      this.getNotifications()
    });
  }

  deleteNotification = (notification) => {
    if (notification) {
      fetch(env.API_URL + `/data/notifications/delete/${notification}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then((res) => res.json()).then((result) => {
        this.getNotifications()
      });
    }

  }
  clickedNotification = (notification, callback) => {
    if (notification) {
      fetch(env.API_URL + `/data/notifications/click/${notification}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then((res) => res.json()).then((result) => {
        this.getNotifications()
        if (callback) {
          callback()
        }
      });
    }

  }




  render() {
    return (
      <div className="App">
        <Routes
          updateMeta={this.updateMeta}
          verifyUser={this.verifyUser}
          signOut={this.signOut}
          getOriginalDateFormat={this.getOriginalDateFormat}
          roundToTwoDecimals={this.roundToTwoDecimals}
          getDateStringFromTs={this.getDateStringFromTs}
          getTsFromDateString={this.getTsFromDateString}
          checkDateValid={this.checkDateValid}
          selectYear={this.selectYear}
          cartUpdated={this.cartUpdated}
          changeActiveOrder={this.changeActiveOrder}
          {...this.props}
          {...this.state}
          sidebarChange={this.sidebarChange}
          getNotifications={this.getNotifications}
          seenNotifications={this.seenNotifications}
          deleteAllNotifications={this.deleteAllNotifications}
          deleteNotification={this.deleteNotification}
          clickedNotification={this.clickedNotification}
        ></Routes>
      </div >
    )
  }
}

export default App;
