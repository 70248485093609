import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input,
    Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';
import editIcon from '../../assets/edit-icon.svg';
import printIcon from '../../assets/print.svg';
import arrowDown from '../../assets/arrowDown.svg';

import saveIcon from '../../assets/save.svg';
import exportIcon from '../../assets/export.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import Checkbox from '../../form-builder/fields/checkbox';

import DateField from '../../form-builder/fields/date';

import PrintHelper from '../../components/printHelper';


import garbageOpionIcon from '../../assets/garbage-option.svg';
import env from "react-dotenv";


import { required, number, minValue, maxValue, minLength, maxLength, length, email, validateDate } from '../../form-builder/validation';
import moment from 'moment';



function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function dynamicSort(property, sortOrder) {
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

class StockPage extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            coldChainPreview: false,
            total: 0,
            products: [],
            items: [],
            series: [],
            reservation: false,
            orderItems: []
        }
    }
    get = () => {

        this._onLoad()


    }
    componentDidMount() {
        this.get()



    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }



    }
    printOrder = (order) => {
        if (order && order._id) {
            this.setState({ printHtml: null }, () => {
                this._apiAsync('print-export-order', { _id: order._id, action: 'print' }).then((res) => {
                    if (res && res.html) {
                        this.setState({ printHtml: res.html })
                    }
                })
            })

        }
    }


    render() {
        let params = this._getParams();
        return (
            <div>

                <Container fluid style={{ paddingRight: 0 }}>

                    <Row>

                        <Col lg="12" style={{ paddingLeft: 0, paddingRight: 20, marginTop: 200 }} id="left-table" >
                            <div className='section-table-title'>
                                <h4>Prethodne narudžbe</h4>
                            </div>
                            <div className='left-table-mt'>

                                <ListBuilder
                                    tabs={false}
                                    sort={false}
                                    hideFilters={true}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'ordinalNumber', label: 'R.BR.'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13 } },
                                        { type: 'text', name: 'userData.orderNumber', label: 'BR. DOK.'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13 }, substr: 15 },
                                        { type: 'text', name: 'numberOfItems', label: 'BR. ARTIKALA'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'finishedTs', label: 'DATUM I VRIJEME'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                        { type: 'text', name: 'processed', label: 'STATUS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 500 }, substr: 20 },
                                        { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: true, width: 'unset', numberWithCommas: true, fieldStyle: { textAlign: 'right' } },

                                    ]}
                                    items={this.state.items.map((item, idx) => {
                                        return {
                                            ...item,
                                            ordinalNumber: (Number(params.page) * Number(params.entries)) + (idx + 1),
                                            finishedTs: item.finishedTs ? this.props.getDateStringFromTs(item.finishedTs, 'DD.MM.YYYY HH:mm') : '',
                                            processed: item.processed ? <p style={{ marginBottom: 0, color: '#49B848' }}>OBRAĐENA</p> : <p style={{ marginBottom: 0, color: '#FD7900' }}>ČEKA SE OBRADA</p>
                                        }
                                    })}
                                    rawItems={this.state.items}
                                    onClick={(item) => {
                                        this.setState({ previewModal: item })
                                    }}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                            </div>

                        </Col>



                    </Row>


                </Container>


                {
                    this.state.previewModal ?
                        <Modal isOpen={this.state.previewModal} size='lg' className="order-modal-wrap" zIndex={9999}>
                            <ModalHeader>
                                <div className='title'>Pregled artikala {this.state.previewModal && this.state.previewModal.userData ? `(${this.state.previewModal.userData.orderNumber})` : null}</div>
                                <Button className="print-button" onClick={() => {
                                    this.printOrder(this.state.previewModal)
                                }}><div className="svg-box"><Isvg src={printIcon} /></div>Štampaj</Button>

                                {
                                    this.state.printHtml ?
                                        <div className='print-helper-wrap'>
                                            <PrintHelper html={this.state.printHtml} />
                                        </div>
                                        :
                                        null
                                }
                            </ModalHeader>
                            <ModalBody className="order-items-modal-wrap">
                                <div className='list-builder-wrap'>
                                    <ListBuilder
                                        tabs={false}
                                        sort={false}
                                        hideFilters={true}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        // total={this.state.total}
                                        hidePagination={true}
                                        showNumeration={false}
                                        fields={[

                                            { type: 'text', name: 'productName', label: 'NAZIV ARTIKLA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13 } },
                                            { type: 'text', name: 'manufacturerName', label: 'PROIZVOĐAČ'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                            { type: 'text', name: 'deadline', label: 'ROK'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                            { type: 'text', name: 'ammount', label: 'KOL'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 } },
                                            { type: 'text', name: 'price', label: 'CIJENA'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 }, numberWithCommas: true },
                                            { type: 'text', name: 'sum', label: 'IZNOS'.translate(this.props.lang), multilang: false, allowSort: false, width: 'unset', fieldStyle: { fontSize: 13, fontWeight: 400 }, numberWithCommas: true },

                                        ]}
                                        items={this.state.previewModal && this.state.previewModal.items.map((item, idx) => {
                                            return {
                                                ...item,
                                                ordinalNumber: idx + 1
                                                // user: item.createdByUsername
                                            }
                                        })}
                                        rawItems={this.state.previewModal && this.state.previewModal.items ? this.state.previewModal.items : []}

                                        params={params}
                                        sortField={params.sortField}
                                        sortType={params.sortType}
                                        updateSort={this.updateSort}
                                        updateParams={this.updateParams}
                                        updateMultipleParams={this.updateMultipleParams}
                                    ></ListBuilder>
                                </div>

                                <div className='footer-wrap'>

                                    <div className='note-wrap'>
                                        {
                                            this.state.previewModal.note ?
                                                <>
                                                    <p>Napomena: </p>
                                                    <span>
                                                        {
                                                            this.state.previewModal.note && this.state.previewModal.note.split('\n') && this.state.previewModal.note.split('\n').length ?
                                                                this.state.previewModal.note.split('\n').map((el, id) => {
                                                                    return (
                                                                        <>
                                                                            {el}<br />
                                                                        </>
                                                                    )
                                                                })
                                                                :
                                                                this.state.previewModal.note
                                                        }
                                                    </span>
                                                </>
                                                :
                                                null
                                        }
                                    </div>

                                    {
                                        this.state.previewModal.processed ?
                                            <div className='status-wrap'>
                                                <p>Status narudžbe: <span style={{ color: '#49B848' }}>OBRAĐENA</span></p>
                                                {
                                                    this.state.previewModal.processedTs ?
                                                        <p className='processed-ts'>{moment.unix(this.state.previewModal.processedTs).format('DD.MM.YYYY HH:mm')}h</p>
                                                        :
                                                        null
                                                }

                                            </div>
                                            :
                                            <div className='status-wrap'>
                                                <p>Status narudžbe: <span style={{ color: '#FD7900' }}>ČEKA SE OBRADA</span></p>
                                            </div>
                                    }
                                </div>


                            </ModalBody>
                            <ModalFooter>
                                <Button color='primary' onClick={() => {
                                    this.setState({ previewModal: null, printHtml: null })
                                }}>Zatvori</Button>
                            </ModalFooter>

                        </Modal>
                        :
                        null
                }

            </div >
        )
    }
}

export default Page(StockPage);