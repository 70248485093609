import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';


class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className={this.props.value ? 'toogle-switch toogle-switch-active' : 'toogle-switch'} >
                <div onClick={() => this.props.onChange(!this.props.value)}>
                    <div></div>
                </div>
                <span>{this.props.label}</span>
            </div>

        );
    }
}

export default Check;