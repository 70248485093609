import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import error from '../assets/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>

                <ModalBody className="delete-modal">
                    <img src={error} />
                    <div className="message" style={{ fontSize: 18 }}>
                        {
                            this.props.error
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'Ok'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;